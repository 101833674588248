
import { defineComponent, onBeforeMount, ref } from "vue";
import { apiGet, apiPut } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "EditCardEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const dollarRate = {
      id: -1,
      name: "Dollar",
      code: "USD",
      we_sell: 1,
      we_buy: 1,
    };
    const fiatCurrencies = ref([dollarRate]);

    const formData = ref({
      description: null,
      rate: null,
      range: null,
      tac: null,
      preview: null,
      // amount: null,
    });
    const loaded = ref(false);
    const formError = ref({});
    const route = useRoute();
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        await apiPut(
          `/admin/cards/entry/${route.params.entryId}`,
          formData.value
        );
        ShowSuccess("Entry Updated Successfully");
      } catch (e:any) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/entry/${route.params.entryId}`
        );

        const fiatDataResponse = await apiGet(
          `/misc/fiat-currencies`,
          (fiatCurrencies.value as any)?.meta ?? {}
        );
        fiatCurrencies.value = [dollarRate, ...fiatDataResponse.data.data.fiat];

        console.log(fiatCurrencies.value);

        const entry = response.data.data.entry;
        formData.value.description = entry.description;
        formData.value.rate = entry.rate;
        formData.value.range = entry.range;
        formData.value.tac = entry.tac;
        // formData.value.preview = entry.rate + 10;
        loaded.value = true;
        Toast.fire({
          icon: "success",
          title: "Card Entry fetched",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);
    return {
      Submit,
      RefreshData,
      fiatCurrencies,
      loaded,
      formData,
      formError,
      loading,
    };
  },
});
