import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify-content-center my-5" }
const _hoisted_2 = { class: "card col-12 col-md-6 col-lg-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "d-flex flex-column mb-1" }
const _hoisted_10 = { class: "text-end" }
const _hoisted_11 = { class: "badge bg-dark" }
const _hoisted_12 = { class: "h6" }
const _hoisted_13 = { class: "mb-3" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DualRingLoader, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.loaded)
              ? (_openBlock(), _createElementBlock("form", {
                  key: 0,
                  class: "",
                  onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Submit && _ctx.Submit(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", null, [
                      _cache[7] || (_cache[7] = _createElementVNode("label", {
                        for: "description",
                        class: "form-label"
                      }, "Type/Description", -1)),
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.description
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.description) = $event)),
                            class: "form-control",
                            id: "description",
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.formData.description]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", null, [
                      _cache[8] || (_cache[8] = _createElementVNode("label", {
                        for: "description",
                        class: "form-label"
                      }, "Range/Denomination", -1)),
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.range
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            pattern: "^(?:\\d+)(?:(?:-\\d+)|(?:,\\d+)+)?$",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.range) = $event)),
                            class: "form-control",
                            id: "range",
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.formData.range]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _cache[9] || (_cache[9] = _createElementVNode("label", {
                        for: "rate",
                        class: "form-label"
                      }, "Rate", -1)),
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.rate
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.rate) = $event)),
                            class: "form-control",
                            id: "rate",
                            step: "0.01",
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.formData.rate]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", null, [
                        _cache[10] || (_cache[10] = _createElementVNode("label", {
                          for: "rate",
                          class: "form-label"
                        }, "Amount", -1)),
                        _createVNode(_component_InputWrapper, {
                          errors: _ctx.formError.rate
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.preview) = $event)),
                              class: "form-control",
                              id: "rate",
                              step: "0.01",
                              placeholder: "Enter amount ",
                              required: ""
                            }, null, 512), [
                              [_vModelText, _ctx.formData.preview]
                            ])
                          ]),
                          _: 1
                        }, 8, ["errors"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fiatCurrencies, (currency) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: currency.id,
                          class: "mb-2"
                        }, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("h5", null, [
                              _createTextVNode(_toDisplayString(currency.code) + " ", 1),
                              _createElementVNode("span", _hoisted_11, _toDisplayString(currency.we_buy), 1)
                            ]),
                            _createElementVNode("span", _hoisted_12, _toDisplayString((_ctx.formData.preview * _ctx.formData.rate * currency.we_buy).toFixed(4)), 1)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", null, [
                      _cache[11] || (_cache[11] = _createElementVNode("label", {
                        for: "tac",
                        class: "form-label"
                      }, "Terms And Condition", -1)),
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.rate
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("textarea", {
                            class: "form-control",
                            id: "tac",
                            rows: "6",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.tac) = $event)),
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.formData.tac]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ])
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("button", { class: "btn btn-dark" }, "Save Card Entry")
                  ], -1))
                ], 32))
              : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _cache[13] || (_cache[13] = _createElementVNode("h1", { class: "text-center" }, "Unable to fetch entry", -1)),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-sm btn-outline-primary",
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
                    }, "Retry")
                  ])
                ]))
          ])
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}